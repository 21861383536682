import { message, notification } from "antd";
import { Requester } from "../globals/configs";
import { LoginStates } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler";


var userCredentials = {
    user: {},
    is_logged_in: false
}

export const loginWithApi = (props ,username,password) => async dispatch => {
    dispatch({
        type: LoginStates.LOGIN_PROGRESS,
        data: {},
        is_loading: true,
        is_logged_in: false
    });

    const res = await Requester.post("/admins/login", {
        username: username,
        password: password
    })
        .then( async res => {
            await localStorage.setItem("_access_token", res.data.data)
            setTimeout(() => {
                dispatch({
                    type: LoginStates.LOGIN_PROGRESS,
                    data: {},
                    is_loading: false,
                    is_logged_in: false
                });
                props.history.push("/home")
            }, 2000)
        })
        .catch(err => {
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data);
                if (error.hasError) {
                    message.error(
                       error.message['errorMessage'],
                    );
                }
            }else {
                console.log(err);
                message.error("Connection failed")
            }
            dispatch({
                type: LoginStates.LOGIN_PROGRESS,
                data: {},
                is_loading: false,
                is_logged_in: false
            });
        });
}


export const getLoginState = () => dispatch => {
    dispatch({
        type: LoginStates.LOGIN_STATE,
        data: {},
        is_loading: false,
        is_logged_in: userCredentials.is_logged_in
    })
}