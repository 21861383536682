import axios from "axios";


export const BASEURL = "https://data-123.myprojects.workers.dev/api/v1";


export const Requester = axios.create({
    baseURL: BASEURL
});


export const AuthRequester = axios.create({
    baseURL: BASEURL,
    headers: {
        'X-Unique-Token': localStorage.getItem("_access_token")
    }
})