import { combineReducers } from "redux";
import { changePasswordReducer } from "./change_password";
import { getListOfServers } from "./home";
import { loginReducer } from "./login";
import { notificationReducer } from "./notification";
import { promotionReducer } from "./promotion";
import { searchReducer } from "./search";
import { serverModification } from "./servers";
import { getListOfUsersReducer } from "./users";


export default combineReducers({
    login: loginReducer,
    servers: getListOfServers,
    serverModify: serverModification,
    users: getListOfUsersReducer,
    user: promotionReducer,
    search: searchReducer,
    password: changePasswordReducer,
    notification: notificationReducer
})
