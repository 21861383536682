import { message } from "antd";



export class ErrorHandler {
    hasError(res) {
        console.log(res);
        try {
            var resDecoded = res;

            var statusCode = resDecoded === "Created" || resDecoded === "Updated" ? 200 : resDecoded["code"];
            var exceptionName = resDecoded["exception"]
            var _exception;
            if (exceptionName == null && statusCode == 200) {
                _exception = { "hasError": false, "response": resDecoded, "message": null, "code": statusCode };
            } else {
                var errorMessage = this._matchApiException(exceptionName);
                _exception = { "hasError": true, "response": null, "message": errorMessage, "code": statusCode };
            }
            console.log(exceptionName)
            console.log(_exception)
            return _exception;

        } catch (err) {
            console.log(err);
            return {
                "hasError": true,
                "response": null,
                "message": { "errorMessage": "Sorry, but something weird happened, but don't worry we have notified our sleepy developers to fix it ðŸ˜" }
            };
        }
    }

    _matchApiException(exceptionName) {
        switch (exceptionName) {
            case "AuthException":
                return { "errorMessage": "Have you forgetten your password ?  because it's wrong" };
            case "NoCustomerException":
                return { "errorMessage": "Not Described" };
            case "InvalidScopeException":
                return { "errorMessage": "An unknown error has occured" };
            case "TokenExpireException":
                return { "errorMessage": "" };
            case "BlockedException":
                return { "errorMessage": "Oops, your account is blocked, please contact support for further details" };
            case "ValidationException":
                return { "errorMessage": "Our server can't accept your inputs please correct it before submitting again" };
            case "NoUserException":
                return { "errorMessage": "Sorry, no any account exist with your provided details" };
            case "NoCustomerException":
                return { "errorMessage": "Sorry, but we couldn't find any customer with provided details" };
            case "UserExistException":
                return { "errorMessage": "Sorry, but a user already exist with same credentials" };
            case "NoServerException":
                return { "errorMessage": "Sorry, couldn't found any server" };
            case "NoFoodException":
                return { "errorMessage": "Sorry, but we couldn't fetch the vendor products try again later" };
            case "NoMenuException":
                return { "errorMessage": "Sorry, but we couldn't fetch the vendor products try again later" };
            case "FileErrorException":
                return { "errorMessage": "Sorry, but the uploaded image must not exceed 2MB size limit" };
            case "UnknownException":
                return { "errorMessage": "Sorry, but something weird happened, but don't worry we have notified our sleepy developers to fix it ðŸ˜" };
            case "UsernameOrIncorrectPasswordException":
                return { "errorMessage": "Sorry, no any account exist with your provided details" };
            case "OperationNotPermitted":
                return { "errorMessage": "Your not authorized to perform that action" };
            default:
                return { "errorMessage": "Sorry, but something weird happened, but don't worry we have notified our sleepy developers to fix it" };
        }
    }
}



export function handleErrors(err, cb) {
    if (err.response) {
        var error = new ErrorHandler().hasError(err.response.data)
        console.log(error)
        if (error.hasError === true) {
            message.open({
                content: error.message.errorMessage,
                type: "error",
            })
        }
    }else {
        cb();
    }
}