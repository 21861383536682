import { act } from "react-dom/test-utils";
import { NotificationTypes } from "../globals/types"

var initState = {
    is_loading: false,
    data: null
}

export const notificationReducer = (state = initState, action) => {
    switch (action.type) {
        case NotificationTypes.SENDING_IN_PROGRESS:
            return {
                ...state,
                is_loading: action.is_loading,
                data: action.data
            }
        case NotificationTypes.SEND_DONE:
            return {
                ...state,
                is_loading: action.is_loading,
                data: action.data
            }
        default:
            return {...state}
    }
}