import { Button, Card, Col, Descriptions, PageHeader, Row, Spin } from 'antd';
import React, { Component } from 'react';
import Meta from 'antd/lib/card/Meta';
import { DeleteOutlined, EditOutlined, FastForwardFilled, FileAddOutlined, FolderAddOutlined, GlobalOutlined, LoadingOutlined, SettingOutlined, UserAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getCurrentState , getServerList } from "../../actions/home"
import { createOrUpdateServer } from "../../actions/servers"

// Styles
import "./styles/styles.css"
import "../../globals/styles.css"
import Avatar from 'antd/lib/avatar/avatar';
import DrawerView from './widgets/drawer';
import Modal from 'antd/lib/modal/Modal';
import HeaderView from '../../widgets/header';

class HomeView extends Component {


    constructor(props) {
        super(props)
        this.state = {
            drawerVisible: false,
            dialog_visible: false,
            is_new: false,
            server_props: {}
        }
    }

    onDrawerChange = (e) => {
        this.setState({
            drawerVisible: e.target.value
        })
    }

    openDrawer = (server) => {
        this.setState({drawerVisible: true, server_props: server, is_new: server ? false : true})
    }

    componentDidMount() {
        this.props.getServerList()
    }

    removeServer = (props) => {
        props.createOrUpdateServer({},"delete",this.state.server_props.uuid)
        setTimeout(() => {
            this.setState({
                dialog_visible: false
            })
        }, 1000)
    }

    openRemoveDialog = (server) =>  {
        this.setState({
            server_props: server,
            dialog_visible: true
        })
    }

    closeDialog = () => {
        this.setState({
            dialog_visible: false
        })
    }
    


    loadingServers = <Row gutter={8}>
            <Col span={4}>
            <Card  bordered={false} actions={[
                <EditOutlined key="edit" />,
                <DeleteOutlined key="delete" />]
            } loading={true}>
                <Meta
                    title="United Kingdom"
                    description="IP: 192.168.1.1"
                ></Meta>
                <Meta
                    description="Status: Running"
                ></Meta>
            </Card>
        </Col>
        <Col span={4}>
            <Card  bordered={false} actions={[
                <EditOutlined key="edit" />,
                <DeleteOutlined key="delete" />]
            } loading={true}>
                <Meta
                    title="Emirates"
                    description="IP: 192.168.1.1"
                ></Meta>
                <Meta
                    description="Status: Running"
                ></Meta>
            </Card>
        </Col>
        <Col span={4} >
            <Card  bordered={false} actions={[
                <EditOutlined key="edit" />,
                <DeleteOutlined key="delete" />]
            } loading={true}>
                <Meta
                    title="Emirates"
                    description="IP: 192.168.1.1"
                ></Meta>
                <Meta
                    description="Status: Running"
                ></Meta>
            </Card>
        </Col>
        <Col span={4} >
            <Card  bordered={false} actions={[
                <EditOutlined key="edit" />,
                <DeleteOutlined key="delete" />]
            } loading={true}>
                <Meta
                    title="Emirates"
                    description="IP: 192.168.1.1"
                ></Meta>
                <Meta
                    description="Status: Running"
                ></Meta>
            </Card>
        </Col>
        <Col span={4} >
            <Card  bordered={false} actions={[
                <EditOutlined key="edit" />,
                <DeleteOutlined key="delete" />]
            } loading={true}>
                <Meta
                    title="Emirates"
                    description="IP: 192.168.1.1"
                ></Meta>
                <Meta
                    description="Status: Running"
                ></Meta>
            </Card>
        </Col>
        <Col span={4} >
            <Card  bordered={false} actions={[
                <EditOutlined key="edit" />,
                <DeleteOutlined key="delete" />]
            } loading={true}>
                <Meta
                    title="Emirates"
                    description="IP: 192.168.1.1"
                ></Meta>
                <Meta
                    description="Status: Running"
                ></Meta>
            </Card>
        </Col>
        
        </Row>


    render() {

        return (
            <div className="home-wrapper">
                <HeaderView></HeaderView>
                <DrawerView new={this.state.is_new} server={this.state.server_props} visible={this.state.drawerVisible} onChange={this.onDrawerChange}></DrawerView>
                <Modal confirmLoading={this.props.serverModf.is_loading} onOk={() => this.removeServer(this.props)} title="Remove Server" visible={this.state.dialog_visible} onCancel={this.closeDialog}>
                    <p>Do you really want to remove <strong>{this.state.server_props ? this.state.server_props.country_name : ""}</strong> ? this operation cannot be undone </p>
                </Modal>
                <div className="contents-wrapper">
                    <p className="title-small" style={{fontWeight: "normal"}}>List of available servers</p>
                        { this.props.servers.is_loading ?  this.loadingServers : <div className="servers-list" >
                                {this.props.servers.servers.map((server) => 
                                    <Col>
                                        <Card style={{marginTop: "10px"}} key={server.uuid}  bordered={false} actions={[
                                            <EditOutlined key="edit" onClick={() => this.openDrawer(server)} />,
                                            <DeleteOutlined key="delete" onClick={() => this.openRemoveDialog(server)} />]
                                        } loading={this.props.servers.is_loading} >
                                            <div className="server-details">
                                                <div className="country-flag" style={{backgroundSize: "cover" , "backgroundImage": `url("${`https://flagcdn.com/${server.country_flag_code.toLowerCase()}.svg`}")`}}></div>
                                                <br></br>
                                                <h1 className="title-smaller" style={{color: "black"}}>{server.country_name}</h1>
                                                <h1 className="desc" style={{opacity: .5}}>{server.server_host}</h1>
                                                {server.is_premium ? <h1 className="desc" style={{color: "green"}}>Premium</h1> : <h1 className="desc" style={{color: "Red"}}>Free</h1>}
                                            </div>
                                        </Card>
                                    </Col>
                                )}
                                <Col>
                                    <div className="add-server" onClick={() => this.openDrawer(null)}>
                                        <Avatar size="large" icon={<FolderAddOutlined></FolderAddOutlined>}></Avatar>
                                        <p>Add new server</p>
                                    </div>
                                </Col>
                            </div>}
                </div>
                <br></br>
                <br></br>
                <br></br>
            </div>
        );
    }
}

const mapPropsToState = (props) => {
    return {
        servers: props.servers,
        serverModf: props.serverModify
    }
}

export default connect(mapPropsToState, {getCurrentState, getServerList, createOrUpdateServer})(HomeView);