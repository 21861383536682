import { PromotionTypes } from "../globals/types"



var initalData = {
    is_loading: false,
    user: null
}


export const promotionReducer = (state = initalData, action) => {
    switch (action.type) {
        case PromotionTypes.PROMOTION_PROGRESS:
            return {
                ...state,
                is_loading: action.is_loading,
                user: action.user
            }
        case PromotionTypes.PROMOTION_STATE:
            return {
                ...state,
                is_loading: action.is_loading,
                user: action.user
            }
        default:
                return {...state}
    }
}