import { ServerTypes } from "../globals/types";




var initialState = {
    is_loading: false,
    data: {}
}

export const serverModification = (state = initialState, action) => {
    switch (action.type) {
        case ServerTypes.CREATE_SERVER:
            return {
                ...state,
                data: action.data,
                is_loading: action.is_loading
            }
        case ServerTypes.UPDATE_SERVER:
            return {
                ...state,
                data: action.data,
                is_loading: action.is_loading
            }
        case ServerTypes.CURRENT_STATE:
            return {
                ...state,
                data: action.data,
                is_loading: action.is_loading
            }
        default:
            return {
                ...state
            }
    }
}