import { Button, Drawer , Input, Modal , Form } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { promoteOrDemote , blockOrUnblockUser} from "../../../actions/promotion"
import { sendNotification } from "../../../actions/notification"
import Moment from "react-moment";

// styling
import "../styles/user_details.css";
import TextArea from 'antd/lib/input/TextArea';




class UserDetailsView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dialog_visible: false,
            is_updated: false,
            total_days: 0,
            user: {}
        }
    }




    onPromote = (props) => {
        this.setState({
            dialog_visible: false,
        });
        props.promoteOrDemote(props.user.uuid,this.state.total_days,"promote", props.isSearch === true ? props.searchQuery : undefined)
        props.onDone()
    }


    onDemote = (props) => {
        this.setState({
            dialog_visible: false,
        });
        props.promoteOrDemote(props.user.uuid,null,"demote", props.isSearch === true ? props.searchQuery : undefined)
        props.onDone()
    }

    onBlockOrUnblock = (props, operation) => {
        props.blockOrUnblockUser(props.user.uuid,operation)
        props.onDone()
    }

    openDialog = (values) => {
        if (this.state.dialog_visible) {
            this.setState({
                dialog_visible: false
            })
        }else {
            this.setState({
                dialog_visible: true
            })
        }
    }


    sendNotification = (values) => {
        if (values.title && values.message) {
            console.log(values);
            this.props.sendNotification(this.state.user.uuid, values.title, values.message)
        }
    }


    render() {
        this.state.user = this.props.user;
        return (
            <div>
                <Drawer
                    title="User Details"
                    visible={this.props.visible}
                    onClose={this.props.onChange} 
                    closable={true}
                    footer={
                        <div style={{textAlign: "right"}}>
                            {this.state.user.is_blocked ?
                            <Button onClick={() => this.onBlockOrUnblock(this.props, "unblock")} type="dashed">Unblock User</Button>
                            : <Button onClick={() => this.onBlockOrUnblock(this.props, "block")} type="dashed" style={{ backgroundColor: "red" ,color: 'white', border: "none",marginRight: 8 }}>Block User</Button> }
                            {(this.state.user.plan_type) == "Premium+" ?
                                <Button type="primary" onClick={this.openDialog} loading={this.props.modified_user.is_loading}>Demote to Starter</Button>
                                : <Button type="primary" onClick={this.openDialog} loading={this.props.modified_user.is_loading}>Promote to Premium</Button>}
                        </div>
                    }>
                        <Modal title={this.state.user.plan_type == "Starter" ? "Enter total of days" : "Demote User"} visible={this.state.dialog_visible} onCancel={this.openDialog} onOk={() => this.state.user.plan_type == "Premium+" ? this.onDemote(this.props) : this.onPromote(this.props)}>
                            {this.state.user.plan_type == "Starter" ? <Input onChange={(value) => this.state.total_days = value.target.value} placeholder="Total of Subscription Days"></Input> : <div><p>Are you sure that you want to demote user to <strong>Starter</strong>?</p></div>}
                        </Modal>

                    <div className="user-details-wrapper">
                        <Avatar size={90} src={this.state.user.avatar}></Avatar>
                        <h1 className="title-small-dark">{this.state.user.full_name}</h1>
                        <p className="desc-margin">{this.state.user.email}</p>
                        <br></br>
                        <div className="user-status">
                            <div className="user-status-wrapper">
                                <p className="desc">Status</p>
                                <p className="desc-margin" style={{color: this.state.user.is_verified ? "green" : "red"}}><strong>{this.props.user.is_verified ? "Verified" : "Need Verification"}</strong></p>
                            </div>
                            <div className="user-status-wrapper">
                                <p className="desc">Blocked</p>
                                <p className="desc-margin" style={{color: !this.state.user.is_blocked ? "green" : "red"}}><strong>{this.props.user.is_blocked ? "User Restricted" : "No Restriction"}</strong></p>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="user-info-wrapper">
                        <p className="desc-opacity">Unique ID</p>
                        <p className="desc-margin"><strong>{this.state.user.uuid}</strong></p>
                    </div>
                    <div className="user-info-wrapper">
                        <p className="desc-opacity">Full Name</p>
                        <p className="desc-margin"><strong>{this.state.user.full_name}</strong></p>
                    </div>
                    <div className="user-info-wrapper">
                        <p className="desc-opacity">Email</p>
                        <p className="desc-margin"><strong>{this.state.user.email}</strong></p>
                    </div>
                    <div className="user-info-wrapper">
                        <p className="desc-opacity">Plan Type</p>
                        <p className="desc-margin"><strong>{this.state.user.plan_type}</strong></p>
                    </div>
                    { this.state.user.plan_type != "Starter" ? <div className="user-info-wrapper">
                        <p className="desc-opacity">Remaining Days</p>
                        <p className="desc-margin"><strong>
                            <Moment diff={this.state.user.plan_start_date} unit="days">{this.state.user.plan_end_date}</Moment>
                        </strong></p>
                    </div> : <div></div>}
                    {this.state.user.plan_type != "Starter" ? <div className="user-info-wrapper">
                        <p className="desc-opacity">Plan Start Date</p>
                        <p className="desc-margin"><strong>
                                <Moment format="YYYY/MM/DD HH:mm">{this.state.user.plan_start_date}</Moment>
                            </strong></p>
                    </div> : <div></div>}
                    {this.state.user.plan_type != "Starter" ? <div className="user-info-wrapper">
                        <p className="desc-opacity">Plan End Date</p>
                        <p className="desc-margin"><strong>
                                <Moment format="YYYY/MM/DD HH:mm">{this.state.user.plan_end_date}</Moment>
                            </strong></p>
                    </div> : <div></div>}
                    <div className="user-info-wrapper">
                        <p className="desc-opacity">Login Type</p>
                        <p className="desc-margin"><strong>{this.state.user.login_type}</strong></p>
                    </div>
                    <br></br>
                    <br></br>
                    <p className="desc-opacity">Send Notification for <strong>{this.state.user.full_name}</strong></p>
                    <p className="desc-opacity">You can also send notification to {this.state.user.full_name} by using firebase console and send a notification to:<br></br>
                    <strong>user_notif_{this.state.user.uuid}</strong> topic.
                    </p>
                    <Form onFinish={this.sendNotification}>
                        <Form.Item name="title" required >
                            <Input required placeholder="Notification Title" />
                        </Form.Item>
                        <Form.Item name="message" required >
                            <TextArea name="message" required placeholder="Notification Message" />
                        </Form.Item>
                        <Button loading={this.props.notification.is_loading} htmlType="submit" style={{ marginTop: "15px",marginRight: 8 }} >Send Notification</Button>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

const mapPropsToState = (props) => {
    return {
        modified_user: props.user,
        notification: props.notification
    }
}

export default connect(mapPropsToState, { promoteOrDemote , sendNotification , blockOrUnblockUser })(UserDetailsView);