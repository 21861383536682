
export const LoginStates = {
    LOGIN_PROGRESS: "LOGIN_PROGRESS",
    LOGIN_STATE: "LOGIN_STATE"
}

export const HomeTypes = {
    DATA_PROGRESS: "DATA_PROGRESS",
    DATA_STATE: "DATA_STATE"
}

export const UsersTypes = {
    DATA_PROGRESS: "USERS_DATA_PROGRESS",
    DATA_STATE: "USERS_DATA_STATE"
}


export const SearchTypes = {
    SEARCH_IN_PROGRESS: "SEARCH_IN_PROGRESS",
    SEARCH_STATE: "SEARCH_STATE"
}

export const ChangePasswordTypes = {
    OPERATION_IN_PROGRESS: "OPERATION_IN_PROGRESS",
    OPERATION_DONE: "OPERATION_DONE"
}


export const NotificationTypes = {
    SENDING_IN_PROGRESS: "SENDING_IN_PROGRESS",
    SEND_DONE: "SEND_DONE"
}

export const PromotionTypes = {
    PROMOTION_PROGRESS: "PROMOTION_PROGRESS",
    PROMOTION_STATE: "PROMOTION_STATE"
}


export const ServerTypes = {
    CREATE_SERVER: "CREATE_SERVER",
    UPDATE_SERVER: "UPDATE_SERVER",
    CURRENT_STATE: "CURRENT_STATE"
}