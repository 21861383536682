import { message } from "antd";
import { AuthRequester } from "../globals/configs";
import { HomeTypes, UsersTypes } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler"

var paginate = {
    page: 0,
    // prevent from calling api to get data when previous page had no data
    pageLock: false
}

var initialUsers = {
    users: [],
    total_premium: 0,
    total_users: 0,
    is_loading: true,
    is_page_loading: false
}

export const getUsersList = (increasePage) => async dispatch => {
    if (increasePage) {
        dispatch({
            type: UsersTypes.DATA_PROGRESS,
            users: initialUsers.users,
            total_premium: initialUsers.total_premium,
            total_users: initialUsers.total_users,
            is_loading: false,
            is_page_loading: true
        });
    }else {
        dispatch({
            type: UsersTypes.DATA_PROGRESS,
            users: [],
            total_premium: 0,
            total_users: 0,
            is_loading: true,
            is_page_loading: false
        });
    }
    if (increasePage) {
        paginate.page++;
        console.log(paginate.page);
    }

    if (paginate.pageLock) {
        return dispatch({
            type: UsersTypes.DATA_PROGRESS,
            users: initialUsers.users,
            total_premium: initialUsers.total_premium,
            total_users: initialUsers.total_users,
            is_loading: false,
            is_page_loading: false
        });;
    }

    await AuthRequester.get(`/admins/getusers?li=20&sk=${paginate.page * 20}`)
        .then((res) => {
            if (paginate.page == 0) {
                initialUsers.users = res.data.data.users;
                initialUsers.total_premium = res.data.data.count;
                initialUsers.total_users = res.data.data.total;
            }else {
                if (res.data.data.users.length == 0) {
                    paginate.pageLock = true;
                    message.error("No more data available to load")
                }
                res.data.data.users.forEach((user) => {
                    initialUsers.users.push(user)
                })
            }
            dispatch({ 
                type: UsersTypes.DATA_PROGRESS,
                users: initialUsers.users,
                total_premium: initialUsers.total_premium,
                total_users: initialUsers.total_users,
                is_page_loading: false,
                is_loading: false
            });
        })
        .catch(err => {
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data);
                if (error.hasError) {
                    message.error(error.message['errorMessage'])
                }
            }else {
                message.error("Connection Failed!")
            }
            dispatch({
                type: UsersTypes.DATA_PROGRESS,
                users: [],
                total_premium: 0,
                total_users: 0,
                is_loading: false,
                is_page_loading: false
            });
        })

}




export const updateUserDetails = (details) => dispatch => {
    var index = initialUsers.users.findIndex((user) => user.uuid == details.uuid);
    initialUsers.users[index] = details;
    dispatch({
        type: UsersTypes.DATA_PROGRESS,
        users: initialUsers.users,
        total_premium: initialUsers.total_premium,
        total_users: initialUsers.total_users,
        is_page_loading: false,
        is_loading: false
    })
}