import React, { Component } from 'react';
import { Button, Card, Col, Descriptions, Drawer, Input, PageHeader, Row, Spin, Statistic } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import HeaderView from '../../widgets/header';
import { getUsersList , updateUserDetails } from "../../actions/users"
import { searchForUser } from "../../actions/search"
import {  blockOrUnblockUser} from "../../actions/promotion"

// styling
import "./styles/styles.css"
import { connect } from 'react-redux';
import { FundViewOutlined, LoadingOutlined, LockOutlined, SearchOutlined, UnlockOutlined, UserSwitchOutlined } from '@ant-design/icons';
import UserDetailsView from './widgets/user_details';
import Search from 'antd/lib/input/Search';
import Modal from 'antd/lib/modal/Modal';

class UsersView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            drawer_visible: false,
            block_dialog: false,

            current_user: {},
            // user restriction
            blocking_user: {},
            searched_query: "",
            is_update_requested_by_search: false,
            operation: ""
        }
    }

    componentDidMount() {
        this.props.getUsersList();
    }
    
    openDetails = (user, isSearch) => {
        if (isSearch) {
            this.setState({
                is_update_requested_by_search: true
            })
        }
        this.setState({
            drawer_visible: true,
            current_user: user
        })
    }

    onDrawerChange = (e) => {
        this.setState({
            drawer_visible: e.target.value
        })
    }

    closeDrawer = () => {
        if (this.state.is_update_requested_by_search) {
            this.setState({
                is_update_requested_by_search: false
            })
        }
        this.setState({
            drawer_visible: false
        })
    }

    applyRestriction = (operation, user, isSearch) => {
        if (isSearch) {
            this.setState({
                is_update_requested_by_search: true
            })
        }
        this.setState({
            block_dialog: true,
            blocking_user: user,
            operation: operation
        })
    }


    proceedRestricting = () => {
        this.props.blockOrUnblockUser(this.state.blocking_user.uuid, this.state.operation);
        if (this.state.is_update_requested_by_search) {
            this.onSearch("")
            this.setState({
                is_update_requested_by_search: false
            })
        }
        this.setState({
            block_dialog: false,
        })
    }

    closeRestrictionDialog = () => {
        this.setState({
            block_dialog: false
        })
    }

    onSearch = (value) => {
        this.setState({
            searched_query: value
        })
        this.props.searchForUser(value)
    }


    render() {
        return (
            <div className="users-wrapper" onScroll={this.handlePagination}>
                <HeaderView></HeaderView>
                <UserDetailsView searchQuery={this.state.searched_query} isSearch={this.state.is_update_requested_by_search} onDone={this.closeDrawer} user={this.state.current_user} visible={this.state.drawer_visible} onChange={this.onDrawerChange}></UserDetailsView>
                {this.props.users.is_loading ? <div></div> : <div className="user-statistics">
                            <Statistic
                                title="Premium Users"
                                value={this.props.users.total_premium}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<UserSwitchOutlined />}
                            />
                            <Statistic
                                title="Total Users"
                                value={this.props.users.total_users}
                                valueStyle={{ color: '#3f8600' }}
                                prefix={<UserSwitchOutlined />}
                            />
                </div>}
                <br></br>
                <Modal title={this.state.operation == "block" ? "Block User" : "Unblock User" } visible={this.state.block_dialog} onOk={this.proceedRestricting} onCancel={this.closeRestrictionDialog}>
                    <p>Are you sure that you want to {this.state.operation == "block" ? "block " : "unblock "}<strong>{this.state.blocking_user.full_name}</strong> ?</p>
                </Modal>
                <p className="title-small">Search for User</p>
                <div className="search-wrapper">
                    <Search loading={this.props.search.is_loading} onSearch={this.onSearch} placeholder="Enter user name or email to search" enterButton="Search" size="large"></Search>
                    {this.props.search.is_loading ? <div></div> : <div className="search-manager">
                        {this.props.search.data ? this.props.search.data.map(user => {
                            return <Card
                                actions={[
                                    <FundViewOutlined onClick={() => this.openDetails(user, true)}></FundViewOutlined>,
                                    (!user.is_blocked ? <LockOutlined onClick={() => this.applyRestriction("block", user, true)}></LockOutlined> : <UnlockOutlined onClick={() => this.applyRestriction("unblock", user, true)} ></UnlockOutlined>)
                                ]}
                                cover={
                                    <div className="user-avatar" style={{backgroundImage: `url("${user.avatar}")`}}></div>
                                }
                            >
                                <h1 style={{color: "black"}} className="desc"><strong>{user.full_name}</strong></h1>
                                <p>{user.email}</p>

                            </Card>
                        }) : <div></div>}
                    </div>}
                </div>
                <br></br>
                <p className="title-small">List of users</p>
                <Spin spinning={this.props.users.is_loading} indicator={<LoadingOutlined></LoadingOutlined>}>
                    {this.props.users.is_loading ? <div></div> : <div className="users-manager">
                        {this.props.users.users.map(user => {
                            return <Card
                                actions={[
                                    <FundViewOutlined onClick={() => this.openDetails(user)}></FundViewOutlined>,
                                    (!user.is_blocked ? <LockOutlined onClick={() => this.applyRestriction("block", user)}></LockOutlined> : <UnlockOutlined onClick={() => this.applyRestriction("unblock", user)} ></UnlockOutlined>)
                                ]}
                                cover={
                                    <div className="user-avatar" style={{backgroundImage: `url("${user.avatar}")`}}></div>
                                }
                            >
                                <h1 style={{color: "black"}} className="desc"><strong>{user.full_name}</strong></h1>
                                <p>{user.email}</p>

                            </Card>
                        })}
                    </div>}
                </Spin>
                {!this.props.users.is_loading && this.props.users.users.length < this.props.users.total_users ? <div>
                    <Button type="primary" loading={this.props.users.is_page_loading} onClick={() => this.props.getUsersList(true)}>Load More</Button>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div> : <div></div>}
            </div>
        );
    }
}

const mapPropsToState = (props) => {
    return {
        users: props.users,
        search: props.search
    }
}

export default connect(mapPropsToState, { getUsersList, updateUserDetails, searchForUser , blockOrUnblockUser })(UsersView);