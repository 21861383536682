import { message } from "antd";
import { AuthRequester } from "../globals/configs";
import { PromotionTypes } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler";
import { searchForUser } from "./search";
import { updateUserDetails , getUsersList } from "./users";




export const promoteOrDemote = (uuid, total_days, operation, query) => dispatch => {
    dispatch({
        type: PromotionTypes.PROMOTION_PROGRESS,
        user: null,
        is_loading: true
    });

    var path = "/admins/users/promotion";
    AuthRequester.post(path , {
        uuid: uuid,
        days: total_days || 0,
        operation: operation
    })
        .then(res => {
            if (query) {
                dispatch(searchForUser(query))
            }
            dispatch(getUsersList())
            dispatch({
                type: PromotionTypes.PROMOTION_PROGRESS,
                user: res.data.data,
                is_loading: false
            });
            message.success(`User ${operation == "promote" ? 'promoted' : 'demoted'} successfully`)
        })
        .catch(err => {
            console.log(err);
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data);
                if (error.hasError) {
                    message.error(error.message['errorMessage'])
                }
            }else {
                message.error("Connection Failed!")
            }
            dispatch({
                type: PromotionTypes.PROMOTION_PROGRESS,
                user: null,
                is_loading: false
            });
        })
}


export const blockOrUnblockUser = (uuid, operation) => dispatch => {
    dispatch({
        type: PromotionTypes.PROMOTION_PROGRESS,
        user: null,
        is_loading: true
    });


    var path = "/admins/users/blocking";
    AuthRequester.post(path , {
        uuid: uuid,
        operation: operation
    })
        .then(res => {
            dispatch(getUsersList())
            dispatch({
                type: PromotionTypes.PROMOTION_PROGRESS,
                user: res.data.data,
                is_loading: false
            });
            message.success(`User ${operation == "block" ? 'blocked' : 'unblocked'} successfully`)
        })
        .catch(err => {
            console.log(err);
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data);
                if (error.hasError) {
                    message.error(error.message['errorMessage'])
                }
            }else {
                message.error("Connection Failed!")
            }
            dispatch({
                type: PromotionTypes.PROMOTION_PROGRESS,
                user: null,
                is_loading: false
            });
        })
}