import { message } from "antd"
import { AuthRequester } from "../globals/configs"
import { NotificationTypes } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler"


var initialData = {
    is_loading: false,
    data: []
}

export const sendNotification = (uuid, title, _message) => dispatch => {
    dispatch({
        type: NotificationTypes.SENDING_IN_PROGRESS,
        is_loading: true,
        data: []
    })

    AuthRequester.post("/admins/notification", {
        uuid: uuid,
        title: title,
        message: _message
    })
        .then(res => {
            message.success("Notification sent successfully")
            dispatch({
                type: NotificationTypes.SENDING_IN_PROGRESS,
                is_loading: false,
                data: "changed"
            })
        })
        .catch(err => {
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data)
                if (error.hasError) {
                    message.error(error.message['errorMessage'])
                }
            }else {
                message.error("Connection Failed")
            }
            dispatch({
                type: NotificationTypes.SENDING_IN_PROGRESS,
                is_loading: false,
                data: []
            })
        })
}