import { message } from "antd"
import { AuthRequester } from "../globals/configs"
import { SearchTypes } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler"


var initialData = {
    is_loading: false,
    data: []
}

export const searchForUser = (query) => dispatch => {
    dispatch({
        type: SearchTypes.SEARCH_IN_PROGRESS,
        is_loading: true,
        data: []
    })

    if (query == "") {
        dispatch({
            type: SearchTypes.SEARCH_IN_PROGRESS,
            is_loading: false,
            data: []
        })
        return;
    }

    AuthRequester.get("/admins/search?q=" + query)
        .then(res => {
            dispatch({
                type: SearchTypes.SEARCH_IN_PROGRESS,
                is_loading: false,
                data: res.data.data
            })
        })
        .catch(err => {
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data)
                if (error.hasError) {
                    message.error(error.message['errorMessage'])
                }
            }else {
                message.error("Connection Failed")
            }
            dispatch({
                type: SearchTypes.SEARCH_IN_PROGRESS,
                is_loading: false,
                data: []
            })
        })
}