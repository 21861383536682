import { Drawer, Space, Form, Input, Button, Switch} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createOrUpdateServer } from "../../../actions/servers"


// styling
import "../styles/drawer.css"

class DrawerView extends Component {


    constructor(props) {
        super(props)
        this.state = {
            has_props_set: false,
            is_premium: false,
            is_recommended: false,
            country_flag_code: 'gb',
            country_name: '',
            server_host: "",
            auth_user: '',
            auth_pass: '',
        }
    }

    onUpdateOrCreate = (props,values) => {
        if (props.server) {
            props.createOrUpdateServer(values,"update", props.server ? props.server.uuid : null)
        }else {
            props.createOrUpdateServer(values,"create", null)
        }
    }

    onCountryChange = (e) => {
        if (e.target.value.length >= 2) {
            this.setState({
                country_flag_code: e.target.value
            })
        }
    }

    render() {
        const layout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 24 },
          };
        return (
            <div className="drawer-view">
                <Drawer title={this.props.new ? "Create Server" : "Modify Server"} visible={this.props.visible} onClose={this.props.onChange} closable={true}>
                    <div className="flag-item" style={{backgroundSize: "cover" , "backgroundImage": `url("${`https://flagcdn.com/${this.props.server ? (this.props.server.country_flag_code || "gb").toLowerCase() : this.state.country_flag_code}.svg`}")`}}>
                    </div>
                    <Form key={this.props.server ? this.props.server.uuid : "new-doc" } {...layout} onFinish={(values) => this.onUpdateOrCreate(this.props,values)} initialValues={{
                        ...( this.props.server ? this.props.server : {})
                    }}>
                        <Form.Item name="country_name" label="Server State Name">
                            <Input type="text"  required={true}></Input>
                        </Form.Item>
                        <Form.Item name="country_flag_code" label="Country Flag Code">
                            <Input type="text"  maxLength={2} onChange={this.onCountryChange} required={true} placeholder="E.g IR, GB, US"></Input>
                        </Form.Item>
                        <Form.Item name="server_host" label="Server Host/IP">
                            <Input type="text"   placeholder="For IKev2 Protocol"></Input>
                        </Form.Item>
                        <Form.Item name="auth_user" label="Username">
                            <Input type="text"   placeholder="For IKev2 auth"></Input>
                        </Form.Item>
                        <Form.Item name="auth_pass" label="Password">
                            <Input type="text"   placeholder="For IKev2 auth"></Input>
                        </Form.Item>
                        <Form.Item name="oauth_user" label="OVPN Username">
                            <Input type="text"   placeholder="For OPENVPN auth"></Input>
                        </Form.Item>
                        <Form.Item name="oauth_pass" label="OVPN Password">
                            <Input type="text"   placeholder="For OPENVPN auth"></Input>
                        </Form.Item>
                        <Form.Item name="o_require_pass" label="OVPN Password" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item name="server_alias_host" label="Alias Host">
                            <Input type="text"   placeholder="Server Alias Host"></Input>
                        </Form.Item>
                        <Form.Item name="recommended_protocol" label="Protocol">
                            <Input type="text" defaultValue="openvpn"  placeholder="Recommended Protocol"></Input>
                        </Form.Item>
                        <Form.Item name="cert" label="OVPN Config">
                            <TextArea required placeholder="In plaintext format"></TextArea>
                        </Form.Item>
                        <Form.Item name="is_premium" label="Premium Server" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item name="override_protocol" label="Override Protocol" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                        <Form.Item valuePropName="checked" name="is_recommended" label="Recommend Server">
                            <Switch  />
                        </Form.Item>
                        <hr></hr>
                        <Form.Item>
                            <Button htmlType="submit" loading={this.props.serverMod.is_loading} type="primary">{this.props.new ? "Create" : "Update"} Server</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

const mapPropsToState = (props) => {
    return {serverMod: props.serverModify}
}

export default connect(mapPropsToState, {createOrUpdateServer})(DrawerView);