import { message } from "antd";
import { AuthRequester } from "../globals/configs";
import { ServerTypes } from "../globals/types"
import { ErrorHandler } from "../utils/error_handler";
import { getServerList } from "./home";


var currentState = {
    is_loading: false,
    data: {}
}

export const createOrUpdateServer = (server,operation, uuid) => dispatch => {
    dispatch({
        type: ServerTypes.CURRENT_STATE,
        data: null,
        is_loading: true
    });

    AuthRequester.post("/admins/modify", {
        ...server,
        uuid: uuid,
        operation: operation
    })
        .then(async res => {
            message.success("Server updated successfully")
            dispatch({
                type: ServerTypes.CURRENT_STATE,
                data: res.data,
                is_loading: false
            });
            dispatch(getServerList())
        })
        .catch(err => {
            if (err.response) {
                var error = new ErrorHandler().hasError(err.response.data)
                if (error.hasError) {
                    message.error(error.message['errorMessage'])
                }
            }else {
                message.error("Connection Failed")
            }
            dispatch({
                type: ServerTypes.CURRENT_STATE,
                data: null,
                is_loading: false
            });
        })
}